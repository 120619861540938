.promolagoon {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .background {
        width: 80%;
        background-color: transparent;
        border-radius: 20px;
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
        }
        
    }
    .judulpromo {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 500;
        color: #2f4d67;
        text-shadow: 2px 2px 4px rgb(151, 151, 151);
        @media (max-width: 768px) {
            width: 100%;
            font-size: 40px;
            color: #2f4d67;
        }
    }
    .container-promo {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            //text-align: center;
            align-items: center;
        }

        .containerwhatsapp {
            display: flex;
            justify-content: center;
            //padding-top: 25px;
            
            .whatsappsumban {
              display: inline-block;
              padding: 10px 125px;
              cursor: pointer;
              border: solid 1px #2f4d67;
              border-radius: 20px;
              background-color: #2f4d67; 
              color: #ffffff;
              font-size: 16px;
              margin-top: 30px;
              &:hover {
                background-color: transparent;
                color: #2f4d67;
                border-color: #2f4d67;
                
            }
            }
    
          }
        

    }
    .promo-gambar {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media (max-width: 768px) {
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        img {
            width: 75%;
            border-radius: 20px;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
    .container-desk {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 768px) {
            width: 100%;
        }
        .juduldesk{
            width: 100%;
            text-align: center;
            font-size: 50px;
            font-weight: 500;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #2f4d67;
            text-shadow: 2px 2px 4px rgb(151, 151, 151);
            @media (max-width: 768px) {
                width: 100%;
                
            }
        }
        .promo-desk {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px ;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: start;
                //margin-left: 50px;
            }
            .penawaranpoin {
                font-size: 20px;
            }
            //text-align: center;
        }
        .judulfasilitas {
            width: 100%;
            text-align: center;
        }
        .judulfasilitas {
            font-size: 50px;
            font-weight: 500;
            font-weight: 500px;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #2f4d67;
            text-shadow: 2px 2px 4px rgb(151, 151, 151);
            @media (max-width: 768px) {
                width: 100%;
                
            }
        }
        .fasilitas-desk {
            width: 100%;
            display: flex;
            flex-direction: row;
            text-align: center;
            @media (max-width: 768px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
                margin-left: 50px;
            }
            
            .fasilitas1 {
                width: 100%;
                .penawaranpoin {
                    font-size: 20px;
                }
            }
            .fasilitas2 {
                width: 100%;
                .penawaranpoin {
                    font-size: 20px;
                }
            }
        }
        
    }
}