.containertitip {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    .bannertitip {
        width: 80%;
        background-color: #1D3D59;
        box-shadow: 3px 3px 3px 3px rgb(128, 128, 128);
        text-align: center;
        padding-bottom: 30px;
        padding-top: 5px;
        border-radius: 20px 20px 40px 40px;
        @media (max-width: 768px) {
            width: 90%;
            border-radius: 20px 20px 20px 20px;
        }
        h1 {
            text-align: center;
            font-size: 35px;
            font-weight: 400;
            margin-bottom: 50px;
            color: rgb(255, 255, 255);
            @media (max-width: 768px) {
                font-size: 30px;
                padding: 40px;
                margin-top: -20px;
                margin-bottom: -20px;
                line-height: 1.8;
            }
        }
        .clusterbutton {
            display: flex;
            justify-content: center;
            //padding-top: 25px;
            
            .cluster-whatsapp {
              display: inline-block;
              padding: 10px 100px;
              cursor: pointer;
              border: solid 1px rgb(200, 169, 92);
              border-radius: 20px;
              background-color: rgb(200, 169, 92); 
              color: #ffffff;
              font-size: 16px;
              transition: background-color 0.3s ease, color 0.3s ease;
              &:hover {
                background-color: transparent;
                color: white;
                border-color: white;
                
            }
            }
          }
    }
}