.lokasi {
  width: 100%;
  padding-bottom: 20px;
    .judul {
      font-size: 65px;
      font-family: "Poppins", sans-serif;
      color: #2f4d67;
      font-weight: 400;
      //margin-top: 75px;
      text-align: center;
      @media (max-width: 768px) {

      }
    }
    .maps-lokasi {
      width: 100%;
      //margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
        overflow: hidden;

      }
      .img-lokasi-dekstop {
        width: 60%;
        //height: 500px;
        border-radius: 10px;
        @media (max-width: 768px) {
          visibility: hidden;
          width: 0%;
          height: 0%;
        }
      }
      .img-lokasi-mobile {
        visibility: hidden;
        width: 0%;
        @media (max-width: 768px) {
          visibility: visible;
          width: 90%;

          margin-top: -20%;
        }
      }
    }
    
  }